import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';

const HoariPopUp = ({ updateSelectedHelp }) => {
    return(
    <div className="popUp">
    <div className="popUpBox">
        <div className="popUpHeader">
            <p>Com funciona</p>
            <div className="closeBTN" onClick={() => updateSelectedHelp(null)}>
                <IconButton  >
                    <CloseIcon />
                </IconButton>
            </div>
        </div>
        <div className="popUpBody2">

            <ul>
                <li>
                    El preu del menú diari és de 12 € (Escollint un primer, segon i postre).
                </li>
                <li>
                    Pots escollir plats per separat i t’anirà sumant el preu.
                </li>
                {
                    /*
                
                <li>
                   Caps de setmana, festius i dies especials els preus són per plat.
                </li>
                <li>
                   Les paelles i fideuà min. 2 persones.
                </li>
            </ul>


           
                */}
                
            </ul>
            <p className="proximitat">* Els nostres productes de proximitat passen per un control sanitari rigorós. La cuina del Racó de sempre, ara a la teva taula.</p>
        </div>
    </div>
</div>
    )   
}
export default HoariPopUp