import * as React from 'react';
import AspectRatio from '@mui/joy/AspectRatio';
import Box from '@mui/joy/Box';
import Button from '@mui/joy/Button';
import Card from '@mui/joy/Card';
import IconButton from '@mui/joy/IconButton';
import Typography from '@mui/joy/Typography';
import EditarModalMenu from './editarMenuModal';
import { useEffect } from 'react';
import DeleteIcon from '@mui/icons-material/Delete';
import { useState } from 'react';


export default function MenuPlats(props) {

  const [plat, setPlat] = useState([]);
  const [visible, setVisible] = useState(false);
  useEffect(() => {

    setPlat(JSON.parse(props.menuInfo.platos));

  }, []);

  const eliminarMenu = () => {
    fetch(process.env.REACT_APP_API_URL + "/eliminarMenu/" + props.menuInfo.id, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })

      .then((response) => response.json())
      .then((data) => {
     
        //aqui hauria de fer un reload de la pagina perque es refresqui
        props.getMenus();
      }
      )
      .catch((error) => {
        console.log(error);
      }
      );

  }
  const handleClose = () => {
    setVisible(false);
  }
  const handleUpdate = () => {
    props.getMenus();
  }
  

  return (
    <>
      <Card variant="outlined" sx={{ width: 320 }}>
        <Typography className="titleBox" level="h2" fontSize="md" sx={{ mb: 0.5 }}>
          {props.menuInfo.fecha}
        </Typography>
        <Typography className="tipusPlat" level="body2">  {props.menuInfo.nombre}</Typography>
        <IconButton
          variant="plain"
          color="neutral"
          size="sm"
          sx={{ position: 'absolute', top: '0.5rem', right: '0.5rem' }}
        >
          <DeleteIcon onClick={eliminarMenu} style={{ color: "#e77878" }} />
        </IconButton>


        <Box sx={{ display: 'flex' }}>
          <div>
            <Typography level="body3">Preu:</Typography>
            <Typography fontSize="lg" fontWeight="lg">
             12€
            </Typography>
          </div>
          <Button
            variant="solid"
            size="sm"
            color="primary"
            sx={{ ml: 'auto', fontWeight: 600 }}
            onClick={() => setVisible(true)}
          >
            Editar
          </Button>
        </Box>
      </Card>
      {
        visible && <EditarModalMenu handleClose={handleClose} menuInfo={props.menuInfo} handleUpdate={handleUpdate}/>

      }
    </>
  );
}