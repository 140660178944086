import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';

const HoariPopUp = ({ updateSelectedHelp }) => {
    return (
        <div className="popUp">
            <div className="popUpBox">
                <div className="popUpHeader">
                    <p>Horaris</p>
                    <div className="closeBTN" onClick={() => updateSelectedHelp(null)}>
                        <IconButton  >
                            <CloseIcon />
                        </IconButton>
                    </div>
                </div>
                <div className="popUpBody2">
                    <div className="popUpBody2Box">
                        <strong>Horari de recollida de plats al local</strong>
                        <p>Dies laborables de les 12:00h a 15:30h</p>

                        {/*
                        <p>Dies festius i cap de setmana 13:00h a les 15:30h</p>
                        */}
                    </div>
                    {/*
                    <div className="popUpBody2Box">
                        <strong>Servei a domicili</strong>
                        <p>Dies laborables (línia freda) de 12:00h a 13:00h</p>
                        <p>Cap de setmana i dies festius (línia calenta: paelles, fideuà, arrossos,…) de les 13:00h a 15:00h.</p>
                    </div>
                    <div className="popUpBody2Box">
                        <strong>Poblacions</strong>
                        <p>Els Monjos, La Ràpita, Cal Rubió, Sardinyola, Muscarola, Les Masuques i Moja (amb un recàrrec de 2,00€ per viatge de dimarts a diumenge).</p>
                    </div>

                    <p className="proximitat">Dilluns tancat i de dimarts a diumenge les comandes online fins a les 11:30h i telefònicament fins les 14:30h. </p>
   */}
                </div>

            </div>
        </div>
    )
}
export default HoariPopUp
