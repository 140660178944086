import React, { useState, useEffect, useRef } from "react";
import ArticuloMenu from "./articuloMenu";
import { CartProvider, useCart } from "react-use-cart";
import Footer from "./footer";
import HelpIcon from '@mui/icons-material/Help';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import AccessTimeFilledIcon from '@mui/icons-material/AccessTimeFilled';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import CloseIcon from '@mui/icons-material/Close';
import ComFuncionaPopUp from "./comFuncionaPopUp";
import HorariPopUp from "./horariPopUp";
import CalendariPopUp from "./calendariPopUp";
import ArrowCircleDownIcon from '@mui/icons-material/ArrowCircleDown';
import ArrowCircleRightOutlinedIcon from '@mui/icons-material/ArrowCircleRightOutlined';
const Producto = () => {

    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [menu, setMenu] = useState([]);
    const [plats, setPlats] = useState([]);

    const [listaMenus, setListaMenus] = useState([]);
    const [listaPrimerPlats, setListaPrimerPlats] = useState([]);
    const [listaSegonPlats, setListaSegonPlats] = useState([]);
    const [listaPostres, setListaPostres] = useState([]);
    const [selectedItem, setSelectedItem] = useState(null);
    const [selectedHelp, setSelectedHelp] = useState(null);
    const [selectedMenuIndex, setSelectedMenuIndex] = useState(0);

    const { addItem } = useCart();
    const [quantity, setQuantity] = useState(0);
    const [selectedOpcion, setSelectedOpcion] = useState(0);
    const [platsCarta, setPlatsCarta] = useState([]);
    const [loadingCarta, setLoadingCarta] = useState(true);
    const menuRef = useRef(null);
    const [pdfUrl, setPdfUrl] = useState(null);
    const [visibilitat, setVisibilitat] = useState(false);

    useEffect(() => {
        setLoading(true);
        setError(null);
        cargarMenus();
        getPDF();
        getvisibilitatcarta();


    }, []);
    const handleIncrement = () => {
        setQuantity(quantity + 1);
    }
    const getvisibilitatcarta = () => {
        fetch(process.env.REACT_APP_API_URL + "/visibilitatcarta", {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
            },
        })
            .then((response) => response.json())
            .then((data) => {
                setVisibilitat(data.visibilitat);
            })
            .catch((error) => {
                console.log(error);
            });
    }


    const handleAddToCart = () => {

        let plat = {};
        if (selectedItem.menu === undefined) {

            plat = {
                id: 0 + 'M' + selectedItem.id,
                name: selectedItem.nombre,
                price: selectedItem.precio,
                cantidad: selectedItem.minim === 1 ? (quantity < 2 ? 2 : quantity) : quantity,
                tipo: 'Primer plat',
                menu: 0,
                minim: selectedItem.minim,
            }

        } else {
            plat = {
                id: selectedItem.menu + "M" + selectedItem.id,
                name: selectedItem.nombre,
                price: selectedItem.precio,
                cantidad: quantity,
                tipo: selectedItem.tipo,
                menu: selectedItem.menu,
                fecha: selectedItem.fecha
            }
        }
        addItem(plat, quantity);
        handleCerrar();
    }

    const handleDecrement = () => {
        if (quantity > 0) {
            setQuantity(quantity - 1);

        }

    }
    const updateSelectedHelp = (selectedHelp) => {
        setSelectedHelp(selectedHelp);
    }

    const handleCerrar = () => {
        setQuantity(0);
        setSelectedItem(null);
    }
    const getPDF = () => {
        fetch(process.env.REACT_APP_API_URL + "/getpdf", {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
            },
        })
            .then((response) => response.json())
            .then((data) => {
                setPdfUrl(data[0].url);

            })
            .catch((error) => {
                console.log(error);
            });
    };

    const cargarMenus = async () => {
        try {
            const response = await fetch(process.env.REACT_APP_API_URL + "/menus", {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    Accept: "application/json",
                },
            });
            const data = await response.json();
            setListaMenus(data);

            const promises = data.map(async (menu) => {
                const platos = JSON.parse(menu.platos);
                const promisesPlatos = platos.map(async (plat) => {
                    const data = await getPlatsMenus(plat.id_plato, plat.tipo, menu.id, menu.fecha);
                    return data;
                });
                const resultsPlatos = await Promise.all(promisesPlatos);
                return resultsPlatos;
            });

            const resultsMenus = await Promise.all(promises);
            const flattenResultsMenus = resultsMenus.flat();
            const primerPlats = flattenResultsMenus.filter((plat) => plat.tipo === "Primer plat");
            const segonPlats = flattenResultsMenus.filter((plat) => plat.tipo === "Segon plat");
            const postres = flattenResultsMenus.filter((plat) => plat.tipo === "Postre");

            setListaPrimerPlats(primerPlats);
            setListaSegonPlats(segonPlats);
            setListaPostres(postres);
            setLoading(false);
        } catch (error) {
            console.log(error);
        }
    };

    const getPlatsMenus = async (id, tipo, idMenu, menuFecha) => {
        try {
            const response = await fetch(process.env.REACT_APP_API_URL + "/plat/" + id, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    Accept: "application/json",
                },
            });
            const data = await response.json();
            data.tipo = tipo;
            data.menu = idMenu;
            data.fecha = menuFecha;
            return data;
        } catch (error) {
            console.log(error);
        }
    };


    const handleClick = (index) => {
        setSelectedMenuIndex(selectedMenuIndex === index ? -1 : index);
        if (selectedMenuIndex !== index) {
            menuRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    };

    // if (loading) return <Loader />;
    if (error) return <p>Error</p>;
    if (!menu) return null;

    const handleChangeVenta = (tipo) => {

        setSelectedOpcion(tipo);

        if (tipo === 1 && platsCarta.length === 0) {
            setLoadingCarta(true);
            fetch(process.env.REACT_APP_API_URL + "/getPlatsCarta", {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    Accept: "application/json",
                },
            })
                .then((response) => response.json())
                .then((data) => {

                    setPlatsCarta(data);
                    setLoadingCarta(false);
                }
                );


        }
    }
    const openPDF = () => {
        //haz que se abra en una nueva pestaña el pdf que se encuentra en el estado pdfUrl
        window.open(pdfUrl, '_blank');
    }


    return (
        <div>
            <div className="headerT">

            </div>
            <div className="divTop">
                <h3 className="subtitle">Avinguda de Catalunya, 26, 08730 Els Monjos, Barcelona</h3>
                <h2 className="title">El Racó de la Cigonya</h2>
                <p className="tipoR">Restaurant Mediterrani · Pizzeria</p>
                <div className="divTop2">
                    ⭐⭐⭐⭐⭐
                    <p className="spanTop">Dona'ns la teva opinio</p>
                    <div className="quesBox">
                        <Tooltip onClick={() => setSelectedHelp(1)} title="Com funciona">
                            <IconButton>
                                <HelpIcon />
                            </IconButton>
                        </Tooltip>
                        <Tooltip onClick={() => setSelectedHelp(2)} title="Els nostres horaris">
                            <IconButton>
                                <AccessTimeFilledIcon />
                            </IconButton>
                        </Tooltip>
                        <Tooltip onClick={() => setSelectedHelp(3)} title="Organitza el teu menú setmanal.">
                            <IconButton>
                                <CalendarMonthIcon />
                            </IconButton>
                        </Tooltip>

                    </div>

                </div>
                {pdfUrl &&
                    <p className="pdfBtn" onClick={openPDF}>Veure PDF amb els menús setmanals </p>
                }
                <br></br>
                
            </div>
          

            <div className="subMenu">
                <p onClick={() => handleChangeVenta(0)}>Menús</p>

                {
                    visibilitat === 1 &&
                    <>
                        <p onClick={() => handleChangeVenta(1)}>Carta</p>
                    </>
                }
            </div>


            <div className="boxMani">

                {loading &&
                    <>
                        {//hace que se repita la estructura de skeleton 5 veces
                            [1, 2, 3,].map((item) => {
                                return <>

                                    <Stack className="stack" spacing={1}>
                                        {/* For variant="text", adjust the height via font-size */}
                                        <Skeleton variant="text" width={540} sx={{ fontSize: '2rem' }} />
                                        <Skeleton variant="text" width={540} sx={{ fontSize: '1rem' }} />
                                        <Skeleton variant="text" width={250} sx={{ fontSize: '1.3rem' }} />
                                        {/* For other variants, adjust the size with `width` and `height` */}

                                        <Skeleton variant="rectangular" width={540} height={80} />
                                        <Skeleton variant="rectangular" width={540} height={80} />
                                        <Skeleton variant="rectangular" width={540} height={80} />
                                        <Skeleton variant="rectangular" width={540} height={80} />
                                    </Stack>
                                    <br></br>

                                </>
                            }
                            )
                        }
                    </>
                }


                {
                    selectedHelp === 1 &&
                    <ComFuncionaPopUp updateSelectedHelp={updateSelectedHelp} />

                }
                {
                    selectedHelp === 2 &&
                    <HorariPopUp updateSelectedHelp={updateSelectedHelp} />

                }
                {
                    selectedHelp === 3 &&
                    <CalendariPopUp updateSelectedHelp={updateSelectedHelp} />
                }


                {!loading &&
                    selectedOpcion === 0 &&


                    listaMenus.map((menu, index) => {
                        return (
                            <div key={menu.fecha} className="boxGen">
                                <h2 className="Insidetitle" onClick={() => handleClick(index)} ref={selectedMenuIndex === index ? menuRef : null}>
                                    {menu.nombre} - {//menu fecha dont use year
                                        menu.fecha
                                            .split("-")
                                            .reverse()
                                            .join("/")
                                            .slice(0, -5)


                                    }
                                    {selectedMenuIndex === index ? (
                                        <IconButton style={{ "float": "right" }} >
                                            <ArrowCircleDownIcon />
                                        </IconButton>
                                    ) : (
                                        <IconButton style={{ "float": "right" }} >
                                            < ArrowCircleRightOutlinedIcon />
                                        </IconButton>
                                    )}
                                </h2>
                                {selectedMenuIndex === index && (
                                    <>
                                        <span className="subMenu2">
                                            Si selecciones un primer, un segon i un postre el preu serà de :12€
                                        </span>
                                        <div className="boxGenPr">
                                            <p className="tipusPlatMenu" >Primers Plats</p>
                                            {listaPrimerPlats.map((plat) => {
                                                if (plat.menu !== menu.id) return null;
                                                else return <div className="productoBox" onClick={() => setSelectedItem(plat)}><ArticuloMenu key={plat.id} plat={plat} type={0} /></div>
                                            })}

                                            <p className="tipusPlatMenu" >Segons Plats</p>
                                            {listaSegonPlats.map((plat) => {
                                                if (plat.menu !== menu.id) return null;
                                                else return <div className="productoBox" onClick={() => setSelectedItem(plat)}><ArticuloMenu key={plat.id} plat={plat} type={0} /></div>
                                            })}

                                            <p className="tipusPlatMenu" >Postres</p>
                                            {listaPostres.map((plat) => {
                                                if (plat.menu !== menu.id) return null;
                                                else return <div className="productoBox" onClick={() => setSelectedItem(plat)}><ArticuloMenu key={plat.id} plat={plat} type={0} /></div>
                                            })}
                                        </div>
                                    </>
                                )}
                            </div>

                        )
                    })
                }
                {
                    selectedOpcion === 1 && !loadingCarta &&


                    <div className="boxGen">
                        {
                            platsCarta
                                .slice()
                                .sort((a, b) => a.tipus - b.tipus)
                                .map((plat) => (
                                    <div className="productoBox" onClick={() => setSelectedItem(plat)}>
                                        <ArticuloMenu key={plat.id} plat={plat} type={1} />
                                    </div>
                                ))
                        }

                    </div>


                }
                {
                    loadingCarta && selectedOpcion === 1 &&
                    <>
                        <Stack spacing={1}>
                            {/* For variant="text", adjust the height via font-size */}
                            <Skeleton variant="text" width={250} sx={{ fontSize: '1.3rem' }} />
                            {/* For other variants, adjust the size with `width` and `height` */}

                            <Skeleton variant="rectangular" width={540} height={80} />
                            <Skeleton variant="rectangular" width={540} height={80} />
                            <Skeleton variant="rectangular" width={540} height={80} />
                            <Skeleton variant="rectangular" width={540} height={80} />
                        </Stack>
                        <br></br>
                    </>
                }



            </div>
            {
                selectedItem &&
                <div className="popUp">
                    <div className="popUpBox">
                        <div className="popUpHeader">
                            <div className="closeBTN" onClick={() => handleCerrar()}>
                                <IconButton  >
                                    <CloseIcon />
                                </IconButton>
                            </div>
                            <p>{selectedItem.nombre}</p>

                        </div>
                        <div>
                            {
                                selectedItem.img !== "" ?
                                    <img className="imgProduct" src={"https://feslatevacomanda.elracodelacigonya.com/fotos/" + selectedItem.img + ".jpg"} />
                                    :
                                    <img className="imgProduct" src={"https://feslatevacomanda.elracodelacigonya.com/fotos/defaultFood.jpg"} />

                            }

                        </div>
                        <div className="popUpBody">
                            <p>{selectedItem.descripcion}</p>
                            <p>{selectedItem.minim === 1 ? "La comanda mínima d'aquest plat es de 2 racions" : ""}</p>
                        </div>
                        <div className="popUpFooter">
                            <div className="articulo__cantidad">
                                <button className="articulo__cantidad-boton" onClick={() => handleDecrement()}>-</button>
                                <input type="number" className="articulo__cantidad-input" value={quantity} readOnly />
                                <button className="articulo__cantidad-boton" onClick={() => handleIncrement()}>+</button>
                            </div>
                            <div className="articulo__boton" onClick={() => handleAddToCart()}>
                                <span>Afegir al carret</span>
                                <span className="priceBtn">{selectedItem.precio}€</span>
                            </div>


                        </div>
                    </div>
                </div>
            }

            <Footer />
        </div>
    );
};
export default Producto;

